import { ProcessLog } from '@/found/modules';
import BasePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';
import Form from '../form';

export default {
  name: 'award_application',
  extends: BasePage,
  components: {
    Form,
    ProcessLog,
  },
  data() {
    return {
      params: {

      },
    };
  },
  created() {
    this.getConfigList('dms_award_application_table');
  },
  methods: {
    // 行按钮隐藏
    clickVisible({ row, val: { code } }) {
      if (code === 'edit') {
        if (row.auditStatus === '1' || row.auditStatus === '2') {
          return false;
        }
        return true;
      }
      if (code === 'process_log' && !row.processCode) {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.modalConfig.title = val.name || '查看';
        this.modalConfig.width = '40%';
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openFull();
      } else if (val.code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
